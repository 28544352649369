@import "../globals.scss";

.container {
  position: fixed;
  top: 0;
  width: 100%;
  display: grid;
  place-items: center;
  z-index: 800;
  transition: 0.2s;
}

.scrolled {
  background-color: #000;
}

.innerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 20px;
  width: 100%;
  max-width: 1240px;
  @include tablet {
    padding: 20px;
  }
}

.logo {
  display: flex;
  @include Gap(20px, true);
  cursor: pointer;
  z-index: 1;
  h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    @include phone {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.nav {
  display: flex;
  align-items: center;
  gap: 30px;
  @include tablet-large {
    position: fixed;
    background-color: #02030c;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0px;
    z-index: 0;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    text-align: center;
    transition: 0.2s;
    &.closed {
      transform: translateX(-100%);
    }
  }
}
.navItem {
  position: relative;
  .navText {
    // font-weight: 500;
    // font-size: 15px;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    @include tablet-large {
      font-weight: 500;
      font-size: 16px;
      line-height: 29px;
      color: #ffffff;
    }
  }
  .clickArea {
    display: flex;
    gap: 9px;
    cursor: pointer;
    @include tablet-large {
      justify-content: center;
    }
  }
  &.isMenu {
    padding: 18px 0;
    @include tablet-large {
      padding: 0;
    }
  }
  &.isLink {
    flex-direction: column;
    p {
      margin: 8px 0;
    }
  }
  .underline {
    height: 2px;
    border-radius: 5px;
    width: 0%;
    background: linear-gradient(212.69deg, #438bfc, #5dae60);
    margin: 0 auto;
    transition: 0.2s;
  }
  &:hover {
    .underline {
      width: 100%;
      @include tablet-large {
        display: none;
      }
    }
  }

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    pointer-events: none;
    opacity: 0;
    padding: 6px;
    background: rgba(247, 247, 247, 0.52);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.13);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    z-index: 1;
    transition: 0.2s;
    transform: translateY(-20px);
    color: black;
    @include tablet-large {
      transform: translateY(0px);
      opacity: 1;
      position: unset;
      background-color: unset;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      backdrop-filter: unset;
      max-height: 0;
      overflow: hidden;
      box-shadow: unset;
    }
    .dropItem {
      padding: 12px 15px;
      width: 200px;
      border-bottom: 1px solid rgba(104, 104, 104, 0.4);
      @include tablet-large {
        border-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: auto;
        p {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #cccccc;
        }
      }
      cursor: pointer;
      .dropText {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
      }
      &:last-of-type {
        border-bottom: 0;
      }
    }
    &:hover {
      pointer-events: all !important;
      opacity: 1 !important;
    }
  }
  &:hover {
    .dropdown {
      pointer-events: all;
      opacity: 1;
      transform: translateY(0);
      @include tablet-large {
        transition: 0.5s;
        &.active {
          max-height: 250px;
          margin-top: 20px;
        }
      }
    }
  }
}

.actions {
  display: flex;
  gap: 31px;
  align-items: center;
  @include tablet-large {
    display: none;
  }
  .login {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    cursor: pointer;
  }
  .register {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 7px 16px;
    cursor: pointer;
    transition: 0.2s;
    background-color: #fff;
    width: 175px;
    height: 39px;
    border: none;
  }
}

.burger {
  display: none;
  cursor: pointer;
  @include tablet-large {
    display: grid;
    place-items: center;
  }
}
