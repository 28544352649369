@import "../globals.scss";

.container {
  h1 {
    font-weight: 600;
    font-size: 25px;
    line-height: 40px;
    color: #e8e8e8;
    @include phone {
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 30px;
    }
  }
  p.desc {
    font-weight: 500;
    font-size: 20px;
    line-height: 42px;
    color: #c0c0c0;
    margin-bottom: 40px;
    @include phone {
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.flex {
  display: flex;
  gap: 25px;
  flex-direction: column;
}

.left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  @include phone {
    flex-direction: column;
  }
}

.prompt {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background: #0f1120;
  border-radius: 10px;
  padding: 8px 16px;
  @include phone {
    flex-direction: column;
    gap: 25px;
  }
  .icon {
    width: 100px;
    height: 100px;
    display: grid;
    place-items: center;
    * {
      width: 100% !important;
    }
  }
  .remint {
    padding: 12.5px;
  }
  p {
    font-weight: 500;
    font-size: 14.2178px;
    line-height: 17px;
    text-align: center;
    color: #fff;
  }
  button {
    font-weight: 500;
    font-size: 14.2178px;
    line-height: 17px;
    background: #060715;
    border: 1px solid #1250f0;
    border-radius: 7.5px;
    color: #ffffff;
    padding: 13px 0;
    width: 150px;
    transition: 0.2s;
    &:hover {
      background-color: #237bff;
    }
  }
}
