@import "../globals.scss";

.container {
  align-self: stretch;
  background: #02030d;
  box-shadow: 0px 4px 80px rgba(177, 182, 228, 0.13);
  padding: 60px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include phone {
    padding: 40px 0;
  }
}

.innerContainer {
  width: 100%;
  max-width: 1240px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  @include Gap(30px);
  flex-wrap: wrap-reverse;
  @include tablet {
    padding: 0 20px;
    flex-direction: column-reverse;
  }
  h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    @include phone {
      font-size: 18px;
      line-height: 20px;
    }
  }

  .left,
  .right {
    display: flex;
    flex-direction: column;
    gap: 30px;
    // width: 100%;
  }
}

.logo {
  display: flex;
  align-items: center;
  @include Gap(15px, true);
}

p.tagline {
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  max-width: 516px;
  @include phone {
    font-size: 12px;
    line-height: 22px;
  }
}

.linksContainer {
  display: flex;
}
.links {
  display: flex;
  flex-direction: column;
  @include Gap(24px);
  &:first-child {
    margin-right: 70px;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    @include phone {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.copyright {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-top: 90px;
  @include phone {
    font-size: 12px;
    line-height: 20px;
  }
}

.joinMail {
  font-weight: 500;
  font-size: 12px;
  line-height: 27px;
  max-width: 444px;
}

.mailForm {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  @include phone {
    gap: 8px;
  }
  .formInput {
    background: rgba(200, 200, 200, 0.35);
    border-radius: 10px;
    border: none;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #c5c5c5;
    min-width: 260px;
    width: 100%;
    &:focus {
      outline: none;
    }
    @include phone {
      font-size: 11px;
      line-height: 11px;
      padding: 8px;
      border-radius: 6px;
      min-width: 100px;
    }
  }
  .signUpBtn {
    background: #30ad53;
    border-radius: 10px;
    border: none;
    padding: 10px 20px;
    color: #ffffff;
    font-size: 12px;
    transition: 0.2s;
    &:hover {
      background-color: #16c548;
    }
    @include phone {
      font-size: 11px;
      line-height: 11px;
      padding: 8px;
      border-radius: 6px;
    }
  }
}
