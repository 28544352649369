@import "../globals.scss";

.backdrop {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: hsla(0, 0%, 22%, 0.5);
}

// width: 100%;
.container {
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #060715;
  border-radius: 10px;
  max-height: 80vh;
  max-width: 1000px;
  overflow-y: scroll;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  -webkit-overflow-scrolling: touch;
  @include phone {
    width: 95%;
  }
}

.innercontainer {
  padding: 40px 50px 60px 50px;
  width: 100%;
  overflow-y: scroll;
  position: relative;
  flex: 1;

  @include phone {
    padding: 20px;
  }
}

.close {
  // position: absolute;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.component {
  margin: 0 auto 15px auto;
  max-width: 800px;
}
