@import "./globals.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
}

body {
  width: 100%;
  overflow-x: hidden;
  overflow: overlay;
}

html {
  scroll-behavior: smooth;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  scroll-behavior: smooth;
  &.dark {
    color: white;
    background: #02030c;
  }
  &.light {
    color: black;
    background-color: #f7f7f7;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    // min-height: 100vh;
  }
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

button {
  cursor: pointer;
}

.errMsg {
  color: #e00000;
  font-size: 12px;
}

.centerItems {
  display: grid;
  justify-items: center;
  align-items: center;
}

.gradientBg {
  background-image: url("../assets/gradient_bg.svg");
  object-fit: cover;
  background-repeat: no-repeat;
  width: 100vw;
  background-size: 100%;

  @include phone {
    background-size: 200%;
  }
  // background-attachment: fixed;
}

* {
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 24px;
    background: linear-gradient(212.69deg, #5dae60, #438bfc);
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    margin: 6px 0;
  }
}

.grecaptcha-badge {
  display: none !important;
}
